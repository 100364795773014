import { GetServerSideProps, InferGetServerSidePropsType } from 'next'
import Head from 'next/head'
import { FunctionComponent } from 'react'
import {
  IFormattedContent,
  IFormattedDocumentation,
  findEntryByPath,
  requestDocumentationBody,
  requestFormattedData,
} from '../components/developer/helpers'
import { DeveloperSection } from '../components/developer/index'
import { Error404 } from '../components/errors/404'
import { serverLogger } from '../utils/serverLogger'

const DeveloperPage: FunctionComponent = ({
  data,
  path,
  selectedContent,
}: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  const docsData: IDocsData = {
    selectedContent,
    list: data,
    path: path,
  }

  return (
    <>
      <Head>
        <title>
          {String(`Mapped Developer Portal • ${selectedContent?.title}`)}
        </title>
      </Head>

      {selectedContent ? (
        <DeveloperSection data={docsData} />
      ) : (
        <Error404 message="The page you're looking for doesn't exist." />
      )}
    </>
  )
}

export const getServerSideProps: GetServerSideProps = async ({
  params,
  resolvedUrl,
  req,
}) => {
  const { data, error } = await requestFormattedData()

  if (error) {
    serverLogger.error('[docs query error]', {
      error,
      req: { resolvedUrl, rawHeaders: req.rawHeaders },
    })
  }

  if (!data) {
    return {
      props: {},
    }
  }

  // redirects to the first connector page if none specified
  if (params?.id?.toString() === 'connectors') {
    let firstConnector = data?.filter((d) => d.slug === 'connectors')?.[0]
      ?.children?.[0]

    return {
      props: {},
      redirect: {
        destination: `/connectors/${firstConnector?.slug}`,
      },
    }
  }

  const selectedContent = findEntryByPath(data, params?.id as any) || null

  if (selectedContent) {
    const body = await requestDocumentationBody(selectedContent?.uid || '')
    selectedContent.body = body?.data || ''
  }

  return {
    props: {
      selectedContent,
      data,
      path: params?.id,
    },
  }
}

export interface IDocsData {
  selectedContent: IFormattedContent | undefined
  list: IFormattedDocumentation[]
  path: string[]
}

export default DeveloperPage
