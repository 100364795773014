import { Link } from '@mapped/rivet/dist/mui/icons'
import { styled } from '@mapped/rivet/dist/mui/styles'
import { FunctionComponent } from 'react'
import ReactMarkdown, { Options } from 'react-markdown'
import { RenderSyntaxHighlighter } from '../developer/tabs'

export const MarkdownRenderer: FunctionComponent<{
  children?: string
  components?: Options['components']
}> = ({ children, components }) => {
  return (
    <MarkdownStyles>
      <ReactMarkdown
        children={children || ''}
        skipHtml={false}
        components={{
          ...CommonMarkdownComponents,
          ...components,
        }}
      />
    </MarkdownStyles>
  )
}

export const CommonMarkdownComponents: Options['components'] = {
  p: ({ node, ...props }) => {
    const text = props.children?.toString()
    const isAnchor = text?.match(/<a ? name="(.*)">/)

    if (isAnchor) {
      return (
        <a
          className="anchor"
          {...({ name: isAnchor[1], href: '#' + isAnchor[1] } as any)}
        >
          <Link />
        </a>
      )
    }

    return <p {...props} />
  },
  a: ({ node, ...props }) => {
    let c: any = { href: props.href }

    if (c.href?.endsWith('::new')) {
      c.href = c.href.replace('::new', '')
      c.target = '_blank'
    }

    return <a {...props} {...c} />
  },
  code: ({ children }) => (
    <RenderSyntaxHighlighter value={children as string} />
  ),
}

const MarkdownStyles = styled.div`
  margin-bottom: 32px;

  & > h2 {
    padding: 24px 0;
    border-bottom: 1px solid #e8e9ea;
    margin-bottom: 33px;
    font-size: 32px;
  }

  & > ul {
    list-style: disc;
    margin-left: 40px;
    margin-bottom: 32px;
  }

  p {
    margin-bottom: 20px;
  }
`
