import { Button } from '@mapped/rivet/dist/mui/material'
import { styled } from '@mapped/rivet/dist/mui/styles'
import Head from 'next/head'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FunctionComponent } from 'react'

export const Error404: FunctionComponent<{ message: string }> = ({
  message,
}) => {
  const router = useRouter()

  return (
    <>
      <Head>
        <title>Mapped Developer Portal • Not Found</title>
      </Head>

      <Container>
        <img src="/img/figure-404.svg" width={220} />

        <h1 className="text-4xl font-medium text-center">{message}</h1>

        <Buttons>
          <Button
            style={{ marginRight: 24 }}
            variant="outlined"
            onClick={() => router.back()}
          >
            Back
          </Button>

          <Link href="/">
            <Button>Visit home</Button>
          </Link>
        </Buttons>
      </Container>
    </>
  )
}

const Container = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 100px;

  img {
    margin: 0 auto;
  }

  h1 {
    color: ${(props) => props.theme.palette.text.primary};
    font-size: 24px;
    margin: 40px 0;
  }
`

const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`
