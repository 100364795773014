import { styled } from '@mapped/rivet/dist/mui/styles'
import { useRouter } from 'next/router'
import { FunctionComponent, useEffect, useMemo, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { Layout } from '../../components/layout'
import { IDocsData } from '../../pages/[...id]'
import { PageTitle } from '../layout/pageTitle'
import { CommonMarkdownComponents } from '../markdown'

export const DeveloperSection: FunctionComponent<IDevPage> = ({ data }) => {
  const [selectedAnchor, setSelectedAnchor] = useState({
    anchor: '',
    parent: '',
  })

  const router = useRouter()

  // Parse string
  const [subSection] = useMemo(
    () => router.asPath.split('#').slice(-1),
    [router]
  )

  useEffect(() => {
    setTimeout(() => {
      const element = document.getElementsByName(subSection)[0]
      element?.scrollIntoView()

      if (window.innerWidth < 992) {
        setTimeout(() => window.scrollBy(0, -90), 400)
      }
    }, 100)
  }, [subSection])

  return (
    <Layout
      data={data}
      anchorManagement={{ setSelectedAnchor, selectedAnchor }}
      direction="row"
    >
      <PageTitle
        title={data?.selectedContent?.title || ''}
        id="docs-title"
        withBorder
      />

      <MarkdownStyles>
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          children={data?.selectedContent?.body || ''}
          components={CommonMarkdownComponents}
        />
      </MarkdownStyles>
    </Layout>
  )
}

const MarkdownStyles = styled.div`
  margin-bottom: 32px;

  img {
    display: unset;
  }

  & > h2 {
    font-size: 24px;
  }

  & ul {
    list-style: disc;
    margin-left: 40px;
    margin-bottom: 32px;
  }

  p {
    margin-bottom: 20px;
  }

  code {
    display: inline-block;
    padding: 0.0625rem 0.25rem;
    color: #292929;
    background-color: #ededed;
    border: 0.0625rem solid rgba(0, 0, 0, 0.12);
    border-radius: 0.25rem;
    box-sizing: border-box;
  }

  table {
    width: 100%;
    display: block;
    overflow-x: auto;
    white-space: nowrap;
    border-collapse: collapse;
    border-top: 0 !important;
    border-bottom: 0 !important;
  }

  table,
  tr,
  th,
  td {
    border: 1px solid ${(props) => props.theme.palette.divider};
    border-collapse: collapse;
  }

  th {
    text-align: left;
    width: auto;
  }

  th,
  td {
    padding: 10px 12px;
    min-width: 30%;
  }

  thead {
    background: rgba(0, 0, 0, 0.02);
  }

  .anchor {
    display: flex;
    position: relative;
    left: -24px;
    color: #5e626b;
    width: 100%;
    height: 36px;
    margin-bottom: -56px;
    align-items: center;
    color: ${(props) => props.theme.palette.text.secondary};

    svg {
      opacity: 0;
      width: 20px;
      transform: rotate(-45deg);
    }

    & + h2 {
      pointer-events: none;
    }

    &:hover > svg {
      opacity: 1;
    }
  }
`

interface IDevPage {
  data: IDocsData
}

export interface IAnchorManagement {
  selectedAnchor: { anchor: string; parent: string }
  setSelectedAnchor: (params: { anchor: string; parent: string }) => void
}
