import { ApolloQueryResult, gql } from '@apollo/client'
import { classnames } from '@mapped/rivet/dist/mapped/utils/classnames'
import { styled } from '@mapped/rivet/dist/mui/styles'
import { isEmpty } from 'lodash'
import { FunctionComponent, memo, useEffect, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import {
  PrismAsyncLight,
  SyntaxHighlighterProps,
} from 'react-syntax-highlighter'
import { dark } from 'react-syntax-highlighter/dist/cjs/styles/prism'
import { CoreApiClient } from '../../../lib/core-api'
import { Services } from '../../../services'
import { logger } from '../../../utils/logger'
// @ts-ignore
import { formatSdl as formatGql } from 'format-graphql'

export const RenderSyntaxHighlighter: FunctionComponent<any> = memo(
  (props) => {
    let value = Array.isArray(props.value) ? props.value[0] : props.value

    const [selectedTab, setSelectedTab] = useState<ETab>(ETab.REQUEST)
    const language = getLanguage(value)

    value = value.replace(`{{${language}}}`, '')

    const responseCodeBlock = handleResponseCode(value)
    const responseType = responseCodeBlock.type as EResponseType

    return (
      <>
        {responseCodeBlock.type !== false && (
          <>
            <Tab
              className={classnames({ isActive: selectedTab === ETab.REQUEST })}
              onClick={() => setSelectedTab(ETab.REQUEST)}
            >
              Request
            </Tab>

            <Tab
              className={classnames({
                isActive: selectedTab === ETab.RESPONSE,
              })}
              onClick={() => setSelectedTab(ETab.RESPONSE)}
              style={{ marginLeft: '10px' }}
            >
              {responseType === EResponseType.RUN ? 'Live' : ''} Response
            </Tab>
          </>
        )}

        <SyntaxHighlighterContainer>
          {selectedTab === ETab.REQUEST && (
            <>
              <CopyBtn value={responseCodeBlock.request} />
              <MemoizedSyntaxHighlighter
                children={responseCodeBlock.request}
                language={language}
              />
            </>
          )}

          <RunResponseBlock
            query={responseCodeBlock.request}
            displayContent={
              selectedTab === ETab.RESPONSE &&
              responseType === EResponseType.RUN
            }
          />

          {selectedTab === ETab.RESPONSE &&
            responseType === EResponseType.STATIC && (
              <StaticResponseBlock response={responseCodeBlock.response!} />
            )}
        </SyntaxHighlighterContainer>
      </>
    )
  },
  (p, n) => p?.value?.toString() === n?.value?.toString()
)

const CopyBtn: FunctionComponent<any> = (props) => {
  const [isCopied, setIsCopied] = useState(false)

  function copy() {
    setIsCopied(true)
    setTimeout(() => setIsCopied(false), 2000)
  }

  return (
    <CopyToClipboard text={props.value}>
      <CopyButton
        style={{ backgroundColor: isCopied ? '#51C0AC' : '' }}
        onClick={copy}
      >
        {isCopied ? 'Copied' : 'Copy'}
      </CopyButton>
    </CopyToClipboard>
  )
}

const StaticResponseBlock: FunctionComponent<{ response: string }> = memo(
  ({ response }) => {
    return (
      <>
        <CopyBtn value={response} />
        <MemoizedSyntaxHighlighter children={response} language="json" />
      </>
    )
  }
)

const RunResponseBlock: FunctionComponent<{
  displayContent: boolean
  query: string
}> = memo(({ displayContent, query }) => {
  const [result, setResult] = useState<ApolloQueryResult<any>>()
  const [error, setError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  async function fetchResult() {
    if (!displayContent || !Services.sandbox.token) {
      return
    }

    try {
      const r = await CoreApiClient(Services.sandbox.token, 'token', {
        uri: Services.sandbox.api_url,
        addTypename: false,
      }).query({
        query: gql`
            query ${query}
          `,
      })

      setResult(r)
      setIsLoading(false)
    } catch (error: any) {
      logger.warn(`[docs query error]`, error)

      setIsLoading(false)
      setError(true)
    }
  }

  useEffect(() => {
    fetchResult()
  }, [query, displayContent])

  if (!displayContent) return null

  if (isLoading) {
    return <StatusMessage>Loading...</StatusMessage>
  }

  if (result?.error || error || isEmpty(result)) {
    return <StatusMessage>An error ocurred!</StatusMessage>
  }

  const resultData = JSON.stringify(result?.data)

  return (
    <>
      <CopyBtn value={resultData} />

      <MemoizedSyntaxHighlighter children={resultData} language="json" />
    </>
  )
})

const SyntaxHighlighter: FunctionComponent<SyntaxHighlighterProps> = (
  props
) => {
  return (
    <PrismAsyncLight
      style={dark}
      showLineNumbers={true}
      wrapLongLines={false}
      customStyle={{
        position: 'relative',
        backgroundColor: '#191F2C',
        border: 0,
        fontSize: 12,
        display: 'flex',
        maxHeight: '500px',
        boxShadow: 0,
        width: '100%',
      }}
      {...props}
      children={format(props.children?.toString()!, props.language!)?.trim()}
    />
  )
}

const MemoizedSyntaxHighlighter = memo(
  SyntaxHighlighter,
  (p, n) => p.children?.toString() === n.children?.toString()
)

function getLanguage(value: string) {
  const language = value?.trim()?.match(/^\{\{(.*)\}\}/)

  return language?.[1] || 'json'
}

function format(value: string, language: string) {
  try {
    switch (language) {
      case 'json':
        return JSON.stringify(JSON.parse(value), null, 2)

      case 'graphql':
        return formatGql(value)

      default:
        return value
    }
  } catch (e) {
    return value
  }
}

const handleResponseCode = (codeBlock: string) => {
  // Check if it will create a "Request" / "Response" block
  const RUN_DELIMITER = '::run::'

  // Check if it will create a "Request" / "Response" block with static content
  const STATIC_REQUEST_DELIMITER = '::static_request::'
  const STATIC_RESPONSE_DELIMITER = '::static_response::'

  // There is no need to build the tabs content
  if (
    !codeBlock?.includes(RUN_DELIMITER) &&
    !codeBlock?.includes(STATIC_REQUEST_DELIMITER)
  ) {
    return {
      type: false,
      request: codeBlock,
      response: null,
    }
  }

  // Run the query against the Response Tab
  if (codeBlock?.includes(RUN_DELIMITER)) {
    return {
      type: 'run',
      request: codeBlock?.replace(RUN_DELIMITER, '') || '',
      response: null,
    }
  }

  const breakString = codeBlock?.split(STATIC_RESPONSE_DELIMITER) || []

  const requestStaticCodeBlock =
    breakString?.[0]
      ?.replace(STATIC_RESPONSE_DELIMITER, '')
      ?.replace(STATIC_REQUEST_DELIMITER, '') || ''

  const responseBlock =
    breakString?.[1]?.replace(STATIC_RESPONSE_DELIMITER, '') || ''

  const responseStaticCodeBlock =
    responseBlock?.replace(STATIC_REQUEST_DELIMITER, '') &&
    responseBlock?.replace(STATIC_RESPONSE_DELIMITER, '')

  return {
    type: 'static',
    request: requestStaticCodeBlock,
    response: responseStaticCodeBlock,
  }
}

const SyntaxHighlighterContainer = styled.div`
  position: relative;
  background-color: #191f2c;
  border: 0;
  border-radius: 8px;
  font-size: 12;
  display: flex;
  margin: 16px 0 32px 0;
  min-height: 50px;
  max-height: '500px';
`

const StatusMessage = styled.span`
  display: flex;
  margin: 20px 40px;
  color: #fff;
`

const Tab = styled.span`
  cursor: pointer;
  margin: 12px 12px 0 0;
  padding: 4px 0;

  &.isActive {
    border-bottom: 3px solid ${(props) => props.theme.palette.primary.main};
  }
`

const CopyButton = styled.span`
  right: 16px;
  position: absolute;
  background: ${(props) => props.theme.palette.grey[700]};
  color: black;
  top: 16px;
  z-index: 100;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  border-radius: 2px;
  padding: 4px 8px;
  border: 0;
`

enum ETab {
  REQUEST,
  RESPONSE,
}

enum EResponseType {
  RUN = 'run',
  STATIC = 'static',
}
